<template>
  <div>
    <!-- bannner图 -->
    <img src="@/assets/contact/banner.png" alt="" class="banner" />
    <!-- 联系我们 -->
    <div class="contact">
      <img src="@/assets/contact/contact.png" alt="" class="contact-logo" />
      <div class="contact-info">
        <img src="@/assets/contact/addr.png" alt="" class="addr-img" />
        <div class="addr">
          <img src="@/assets/contact/logo.png" alt="" class="logo-img" />
          <div class="addr-detail">
            <p>{{ addrInfo.addr }}</p>
            <p>{{ addrInfo.eaddr }}</p>
            <p>TEL:{{ addrInfo.tel }}</p>
            <p>MAIL:{{ addrInfo.email }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 加入我们 -->
    <div class="join">
      <img src="@/assets/contact/join.png" alt="" class="join-img" />
      <div class="job-list">
        <div
          class="job-item"
          @click="item.show = !item.show"
          v-for="item in hireList"
          :key="item.id"
        >
          <div class="job-title">
            <span class="job-text">{{ item.title }}</span>
            <img
              src="@/assets/contact/down.png"
              alt=""
              class="down-img"
              v-if="!item.show"
            />
            <img src="@/assets/contact/up.png" alt="" class="up-img" v-else />
          </div>
          <div class="job-desc" v-if="item.show">
            <div class="duty">
              <p>主要职责:</p>
              <div v-html="item.zz_content"></div>
            </div>
            <div class="require">
              <p>任职资格:</p>
              <div v-html="item.zg_content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRecruitList, getAddr } from "@/api";
export default {
  name: "Contact",
  data() {
    return {
      show: false,
      hireList: [],
      addrInfo: {},
    };
  },
  mounted() {
    this.getHire();
    this.getAddr();
  },
  methods: {
    // 获地址信息置
    async getAddr() {
      try {
        const result = await getAddr();
        this.addrInfo = result.info;
      } catch (error) {
        alert(error);
      }
    },
    // 获取招聘列表
    async getHire() {
      try {
        const result = await getRecruitList();
        this.hireList = result.list;
        this.hireList = this.hireList.map((item) => {
          this.$set(item, "show", false);
          return item;
        });
        console.log(this.hireList);
      } catch (error) {
        alert(error);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
  height: 320px;
  display: block;
}
.contact {
  padding: 35px 43px 50px 43px;
  .contact-logo {
    display: block;
    width: 161px;
    height: 54px;
  }
  .contact-info {
    margin-top: 27px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .addr-img {
      width: 281px;
      height: 188px;
      margin-right: 39px;
    }
    .addr {
      position: relative;
      flex: 1;
      .logo-img {
        width: 50px;
        height: 46px;
        position: absolute;
        top: 0px;
        right: 0px;
      }
      .addr-detail {
        margin-top: 79px;
        border-top: 1px solid #000000;
        padding-top: 31px;
        p {
          font-size: 7px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #000000;
          padding-bottom: 10px;
          &:last-child {
            padding-bottom: 0px;
          }
        }
      }
    }
  }
}
.join {
  padding: 50px 42px 53px 43px;
  .join-img {
    width: 156px;
    height: 54px;
    display: block;
  }
  .job-list {
    margin-top: 13px;
    .job-item {
      .job-title {
        padding: 10px 0px;
        width: 100%;
        display: flex;
        border-bottom: 1px solid #f3f3f3;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .job-text {
          font-size: 8px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #000000;
        }
        .down-img {
          width: 6px;
          height: 4px;
        }
        .up-img {
          width: 6px;
          height: 4px;
        }
      }
      .job-desc {
        padding: 15px 0px 5px 0px;
        font-size: 7px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #000000;
        .require {
          margin-top: 15px;
        }
      }
    }
  }
}
</style>
